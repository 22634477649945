import React from "react";

import { Icon, Table, Popup, Label } from "semantic-ui-react";

const FeaturesTable = () => (
  <Table celled textAlign="center" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={8}></Table.HeaderCell>
        <Table.HeaderCell width={4}>Salesforce Identity</Table.HeaderCell>
        <Table.HeaderCell width={4}>CYM Identity</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign="left">Number of apps</Table.Cell>
        <Table.Cell>
          <Popup trigger={<Label>Limited</Label>}>
            It's recommended to only have a few hundred connected apps per org
          </Popup>
        </Table.Cell>
        <Table.Cell>
          <Popup trigger={<span>Millions</span>}>
            You can have millions of application records since it uses Salesforce
            SObjects
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Claim Management</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Dynamic Client Registration</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Dynamic Client Management</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">Extensible Data Model</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            Your can add custom attributes to each application definition
          </Popup>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign="left">SDKs</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            A curated list of Open Source SDKs is available with configuration
            documentation to get you started quickly
          </Popup>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default FeaturesTable;
